import { lazy } from 'react'
import {
  ROUTE_CREATE_TOURNAMENTS_PATH,
  ROUTE_EDIT_TOURNAMENTS_PATH,
  ROUTE_TOURNAMENT_ID_PATH,
  ROUTE_TOURNAMENT_TEAMS_PATH,
} from 'Scenes/constants'

export const tournamentsRouteConfig = [
  // {
  //   path: ROUTE_TOURNAMENTS_PATH,
  //   exact: true,
  //   lazy: true,
  //   component: lazy(() => import(/* webpackChunkName: "SceneTournament" */ './SceneTournamentList')),
  // },
  // {
  //   path: ROUTE_ARCHIVE_TOURNAMENTS_PATH,
  //   exact: true,
  //   lazy: true,
  //   component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneTournamentsArchive')),
  // },
  {
    path: ROUTE_CREATE_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneCreateTournament')),
  },
  {
    path: ROUTE_EDIT_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneEditTournament')),
  },
  {
    path: ROUTE_TOURNAMENT_TEAMS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneEditTournamentTeams')),
  },
  {
    path: ROUTE_TOURNAMENT_ID_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneTournament" */ './SceneTournament')),
  },
]
