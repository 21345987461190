import { lazy } from 'react'
import {
  ROUTE_CONTEST_ID_INVITATION_PATH,
  ROUTE_CONTEST_ID_PATH,
  ROUTE_CONTEST_ID_PATH_PAYMENTS,
  ROUTE_CREATE_CONTEST_PATH,
  ROUTE_EDIT_CONTEST_PATH,
} from 'Scenes/constants'

export const contestsRouteConfig = [
  // {
  //   path: ROUTE_CONTESTS_PATH,
  //   exact: true,
  //   lazy: true,
  //   component: lazy(() => import(/* webpackChunkName: "SceneContest" */ './SceneContestList')),
  // },
  // {
  //   path: ROUTE_CONTESTS_ARCHIVE_PATH,
  //   exact: true,
  //   lazy: true,
  //   component: lazy(() => import(/* webpackChunkName: "SceneContest" */ './SceneContestsArchive')),
  // },
  {
    path: ROUTE_CREATE_CONTEST_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateContest" */ './SceneCreateContest')),
  },
  {
    path: ROUTE_EDIT_CONTEST_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateContest" */ './SceneCreateContest')),
  },
  {
    path: ROUTE_CONTEST_ID_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneContest" */ './SceneContest')),
  },
  {
    path: ROUTE_CONTEST_ID_INVITATION_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import('./SceneAcceptInvitation')),
  },
  {
    path: ROUTE_CONTEST_ID_PATH_PAYMENTS,
    exact: true,
    lazy: true,
    component: lazy(() => import('./SceneContestPayments')),
  },
]
