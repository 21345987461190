import { useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useOpaque } from 'hooks/useOpaque'
import React, { useMemo } from 'react'
import { When } from 'react-if'
import { useLocation } from 'react-router'
import {
  ROUTE_EDIT_CONTEST_PATH,
  ROUTE_EDIT_MAP_PATH,
  ROUTE_EDIT_PREFAB_PATH,
  ROUTE_EDIT_TOURNAMENTS_PATH,
  ROUTE_EVENTS_ARCHIVE_PATH,
  ROUTE_EVENTS_PATH,
  ROUTE_MAPS_PATH,
  ROUTE_PREFABS_PATH,
  ROUTE_SEARCH_PATH,
} from 'Scenes/constants'

import { BREAKPOINT_MIN_SM } from '../../../Theme/stylesUtil'
import MainHeaderControls from '../MainHeaderControls'

const MainHeader = ({ searchHeader, subheader, opaqueHeight = 110, titleHeader }) => {
  const { pathname } = useLocation()
  const isOpaque = useOpaque(opaqueHeight)
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)

  const config = useMemo(() => {
    switch (pathname) {
      case ROUTE_EVENTS_PATH:
        return { title: 'Events', marginBottom: '-112px', backgroundColor: 'background.paper' }
      case ROUTE_EVENTS_ARCHIVE_PATH:
        return { title: 'Events', marginBottom: '-112px', backgroundColor: 'background.paper' }
      case ROUTE_MAPS_PATH:
        return { title: 'Maps', marginBottom: '-112px', backgroundColor: 'background.paper' }
      case ROUTE_PREFABS_PATH:
        return { title: 'Prefabs', marginBottom: '-112px', backgroundColor: 'background.paper' }
      default:
        return ''
    }
  }, [pathname])

  const paths = [ROUTE_MAPS_PATH, ROUTE_PREFABS_PATH, ROUTE_SEARCH_PATH]
  const hiddenSubHeaderPaths = [
    ROUTE_SEARCH_PATH,
    ROUTE_EDIT_PREFAB_PATH,
    ROUTE_EDIT_MAP_PATH,
    ROUTE_EDIT_CONTEST_PATH,
    ROUTE_EDIT_TOURNAMENTS_PATH,
  ]

  if (paths.includes(pathname) && !subheader && !searchHeader) return

  const showSearchHeader = pathname === ROUTE_SEARCH_PATH && isOpaque && searchHeader
  const showSubHeader =
    !hiddenSubHeaderPaths.includes(pathname) || (hiddenSubHeaderPaths.includes(pathname) && isOpaque)

  return (
    isBiggerThanSMScreen && (
      <AppBar
        color="primary"
        sx={(theme) => ({
          position: isOpaque ? 'sticky' : 'absolute',
          marginBottom: config?.marginBottom ?? '-64px',
          backgroundColor: isOpaque
            ? showSearchHeader
              ? theme.palette.background.paper
              : alpha(theme.palette.background.paper, 0.5)
            : config?.backgroundColor ?? 'transparent',
          backgroundImage: 'initial',
          boxShadow: theme.shadows[isOpaque ? 1 : 0],
          display: isBiggerThanSMScreen ? 'block' : 'none',
          transition: theme.transitions.create(['width', 'margin, background-color', 'box-shadow', 'backdrop-filter'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          zIndex: isOpaque && theme.zIndex.appBar,
          backdropFilter: isOpaque && 'blur(20px)',
        })}
      >
        <Toolbar
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
          })}
        >
          {titleHeader ? (
            titleHeader
          ) : (
            <When condition={!!config?.title}>
              <Typography
                variant="h3"
                color="text.primary"
                ml={2}
                width="60%"
              >
                {config?.title}
              </Typography>
            </When>
          )}
          {showSearchHeader && (
            <Box sx={(theme) => ({ marginLeft: theme.spacing(1), width: '60%' })}>{searchHeader}</Box>
          )}
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: theme.spacing(0, 1),
              width: '40%',
              ...theme.mixins.toolbar,
            })}
          >
            <MainHeaderControls isOpaque={isOpaque} />
          </Box>
        </Toolbar>

        {showSubHeader && subheader}
      </AppBar>
    )
  )
}

export default MainHeader
export * from './slots'
