import { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  _DEPRECATED_ROUTE_ARCHIVE_TOURNAMENTS_PATH,
  _DEPRECATED_ROUTE_CONTESTS_ARCHIVE_PATH,
  _DEPRECATED_ROUTE_CONTESTS_PATH,
  _DEPRECATED_ROUTE_TOURNAMENTS_PATH,
  ROUTE_EVENTS_ARCHIVE_PATH,
  ROUTE_EVENTS_PATH,
} from 'Scenes/constants'

import RoutesFromConfig from '../RoutesFromConfig'
import { contestsRouteConfig } from './Contests/routes'
import { tournamentsRouteConfig } from './Tournaments/routes'

const config = [
  {
    path: ROUTE_EVENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneEvents" */ './SceneEventsList')),
  },
  {
    path: ROUTE_EVENTS_ARCHIVE_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneEvents" */ './SceneEventsArchiveList')),
  },
  ...contestsRouteConfig,
  ...tournamentsRouteConfig,
]

const EventsScene = () => (
  <Switch>
    {/* TODO: remove redirects after some time after tournaments release */}
    <Route
      exact
      path={[_DEPRECATED_ROUTE_CONTESTS_PATH, _DEPRECATED_ROUTE_TOURNAMENTS_PATH]}
      render={() => <Redirect to={ROUTE_EVENTS_PATH} />}
    />
    <Route
      exact
      path={[_DEPRECATED_ROUTE_CONTESTS_ARCHIVE_PATH, _DEPRECATED_ROUTE_ARCHIVE_TOURNAMENTS_PATH]}
      render={() => <Redirect to={ROUTE_EVENTS_ARCHIVE_PATH} />}
    />
    <RoutesFromConfig config={config} />
  </Switch>
)

export default EventsScene
