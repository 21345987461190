import { useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { EventCard } from 'Components/_common/EventCard/EventCard'
import { useCategoryName, usePlayersCountName } from 'Queries/Dictionaries'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import React from 'react'
import { navigateToContestPage } from 'Scenes/navigators'
import { BREAKPOINT_MIN_MD } from 'Theme/stylesUtil'
import { userImageSelector } from 'utils/user-images-utils'

import Box from '../../Theme/Box'
import { ArchiveRestoreEvent } from '../_common/ArchiveEvent/ArchiveRestoreEvent'
import Timer from '../_common/Timer'
import { ArchiveRestoreContestModal } from './ArchiveRestoreContestModal'
import ContestStepper from './ContestStepper'
import { contestStepsForMobile, getContestStepperState } from './utils'

const ContestEventCard = ({ event, owner, disableStepper = false }) => {
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)
  const categoryName = useCategoryName(event?.category_id)
  const playersCountName = usePlayersCountName(event?.players_count_id)

  if (!event) return null

  const eventId = event.id
  const title = event.title
  const backgroundSrc =
    event.contest_images?.[event.contest_images.length - 1].urls[isBiggerThanMDScreen ? 'large' : 'medium']
  const prize = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(event.prize_value)
  const link = navigateToContestPage(eventId, true)
  const ownerName = owner?.login
  const ownerAvatarSrc = userImageSelector(owner?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')
  const stepper = disableStepper ? null : <ContestStepper contest={event} />
  const getMobileStepper = () => (
    <Box display="grid">
      <Typography
        color="text.secondary"
        variant="h6"
      >
        {contestStepsForMobile[getContestStepperState(event.state)]?.name}
      </Typography>
      <Typography
        color="text.tertiary"
        variant="caption"
      >
        {contestStepsForMobile[getContestStepperState(event.state)]?.stepStartDate(event)}
      </Typography>
      {getContestStepperState(event.state) === 0 && <Timer seconds={event.time_add_maps_countdown_sec} />}
    </Box>
  )

  const renderRestoreEvent = () =>
    event.is_soft_deleted ? (
      <ArchiveRestoreEvent
        renderRestoreModal={(props) => (
          <ArchiveRestoreContestModal
            eventId={eventId}
            eventTypeName="contest"
            withEdit
            {...props}
          />
        )}
      />
    ) : null

  return (
    <EventCard
      eventId={eventId}
      eventType="map contest"
      title={title}
      backgroundSrc={backgroundSrc}
      categoryName={categoryName}
      playersCountName={playersCountName}
      prize={prize}
      link={link}
      ownerName={ownerName}
      ownerAvatarSrc={ownerAvatarSrc}
      stepper={stepper}
      mobileStepper={getMobileStepper()}
      renderRestoreEvent={renderRestoreEvent}
    />
  )
}

export { ContestEventCard }
