import { Box } from '@mui/material'
import { forwardRef } from 'react'

const ScrollContainer = ({ sx, ...props }, ref) => (
  <Box
    {...props}
    sx={{
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        height: '6px',
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        borderRadius: '4px',
        background: 'rgba(255, 255, 255, 0.3)',
      },
      ...sx,
    }}
    ref={ref}
  />
)

export default forwardRef(ScrollContainer)
