import { Box } from '@mui/material'
import { useAuth } from 'Components/Auth'
import { Link, useLocation } from 'react-router-dom'
import {
  ROUTE_BLOG_CREATE_PATH,
  ROUTE_CREATE_CONTEST_PATH,
  ROUTE_CREATE_MAP_PATH,
  ROUTE_CREATE_NEW_ACCOUNT_PATH,
  ROUTE_CREATE_PREFAB_PATH,
  ROUTE_LOGIN_HASH,
} from 'Scenes/constants'
import {
  navigateToEventsListPage,
  navigateToMapsListPage,
  navigateToPrefabsListPage,
  navigateToProfilePage,
  navigateToRootPage,
} from 'Scenes/navigators'
import Button from 'Theme/Button'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS, FA_KIT_ICONS } from 'Theme/font-awesome-icon-constants'

import { UserAvatar } from './UserAvatar'

// Notifications and prefabs buttons are needed, but these pages are not currently implemented
const buttons = [
  { title: 'home', icon: FA_ICONS.HOUSE, link: navigateToRootPage(true) },
  { title: 'maps', icon: FA_ICONS.MAP, link: navigateToMapsListPage(true) },
  { title: 'prefabs', icon: FA_KIT_ICONS.PREFAB, link: navigateToPrefabsListPage(true) },
  { title: 'events', icon: FA_ICONS.TROPHY, link: navigateToEventsListPage(true) },
  // { title: 'notifications', icon: FA_ICONS.BELL, link: '' },
]

const paths = [
  ROUTE_CREATE_NEW_ACCOUNT_PATH,
  ROUTE_LOGIN_HASH,
  ROUTE_CREATE_CONTEST_PATH,
  ROUTE_CREATE_MAP_PATH,
  ROUTE_CREATE_PREFAB_PATH,
  ROUTE_BLOG_CREATE_PATH,
]

const MobileMainSidebar = () => {
  const { id } = useAuth()
  const location = useLocation()

  const Container = ({ children, sx }) => (
    <Box
      width="100%"
      height="56px"
      position="fixed"
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      zIndex="drawer"
      backgroundColor="background.paper"
      sx={sx}
    >
      {children}
    </Box>
  )
  if (paths.includes(location.pathname) || location.pathname.split('/').includes('invitation')) return

  return (
    <Container>
      {buttons.map((item) => (
        <Link
          to={item.link}
          key={item.title}
        >
          <Button variant="label">
            <FAIcon
              iconClassName={item.icon}
              color={location.pathname === item.link ? 'text.primary' : 'text.tertiary'}
            />
          </Button>
        </Link>
      ))}
      <UserAvatar
        id={id}
        isSelected={id && location.pathname === navigateToProfilePage(id, true)}
      />
    </Container>
  )
}

export default MobileMainSidebar
