import NotFound from 'Components/_common/NotFound'
import LoginModal from 'Components/Auth/LoginForm/loginModal'
import SocialLoginRedirect from 'Components/Auth/SocialLoginRedirect'
import MainHeader from 'Components/Layout/MainHeader'
import MainLayout from 'Components/Layout/MainLayout'
import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'

import AnimatedLoader from '../Components/_common/AnimatedLoader/AnimatedLoader'
import LostPasswordForm from '../Components/Auth/LostPasswordForm'
import SetNewEmail from '../Components/Auth/SetNewEmail'
import SetPasswordForm from '../Components/Auth/SetPasswordForm'
import ErrorBoundaryHandler from '../utils/error-boundary-handler'
import AuthScene from './Auth'
import SceneMobileLogin from './Auth/SceneMobileLogin'
import {
  _DEPRECATED_OLD_ROUTE_CONTESTS_PATH,
  ROUTE_BLOG_PATH,
  ROUTE_CHAT_PATH,
  ROUTE_COLLECTIONS_PATH,
  ROUTE_COMMUNITY_FAVORITES_PATH,
  ROUTE_CREATE_NEW_ACCOUNT_PATH,
  ROUTE_EVENTS_PATH,
  ROUTE_GUIDES_PATH,
  ROUTE_LOGIN_HASH,
  ROUTE_LOGIN_PATH,
  ROUTE_LOGIN_SOCIAL,
  ROUTE_MAPS_PATH,
  ROUTE_OAUTH2_PATH,
  ROUTE_PREFABS_PATH,
  ROUTE_PRIVACY_PATH,
  ROUTE_PROFILE_PATH,
  ROUTE_RESET_NEW_PASSWORD_HASH,
  ROUTE_RESET_PASSWORD_HASH,
  ROUTE_ROOT_PATH,
  ROUTE_SEARCH_PATH,
  ROUTE_SET_NEW_EMAIL,
  ROUTE_TERMS_AND_CONDITIONS_PATH,
} from './constants'
import DeprecatedContestsScene from './DeprecatedContests'
import EventsScene from './Events'
import HomeScene from './Home'
import MapsScene from './Maps'
import PrefabScene from './Prefabs'
import RoutesFromConfig from './RoutesFromConfig'

const config = [
  {
    path: ROUTE_ROOT_PATH,
    exact: true,
    lazy: false,
    component: HomeScene,
  },
  {
    path: ROUTE_CREATE_NEW_ACCOUNT_PATH,
    exact: false,
    lazy: false,
    component: AuthScene,
  },
  {
    path: ROUTE_LOGIN_SOCIAL,
    exact: true,
    lazy: false,
    component: SocialLoginRedirect,
  },
  {
    path: ROUTE_LOGIN_PATH,
    exact: false,
    lazy: false,
    component: SceneMobileLogin,
  },
  {
    path: ROUTE_MAPS_PATH,
    exact: false,
    lazy: false,
    component: MapsScene,
  },
  {
    path: ROUTE_PREFABS_PATH,
    exact: false,
    lazy: false,
    component: PrefabScene,
  },
  {
    path: ROUTE_EVENTS_PATH,
    exact: false,
    lazy: false,
    component: EventsScene,
  },
  {
    path: _DEPRECATED_OLD_ROUTE_CONTESTS_PATH,
    exact: false,
    lazy: false,
    component: DeprecatedContestsScene,
  },
  {
    path: ROUTE_COMMUNITY_FAVORITES_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCommunityFavorites" */ './CommunityFavorites')),
  },
  {
    path: ROUTE_PROFILE_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneProfile" */ './Profile')),
  },
  {
    path: ROUTE_COLLECTIONS_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './Collections')),
  },
  {
    path: ROUTE_SEARCH_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneSearch" */ './Search')),
  },
  {
    path: ROUTE_BLOG_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './Blog')),
  },
  {
    path: ROUTE_GUIDES_PATH,
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './Wiki')),
  },
  {
    path: ROUTE_CHAT_PATH,
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './Chat')),
  },
  {
    path: ROUTE_PRIVACY_PATH,
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './PrivacyPolicy')),
  },
  {
    path: ROUTE_TERMS_AND_CONDITIONS_PATH,
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './TermsAndConditions')),
  },
  {
    path: ROUTE_OAUTH2_PATH,
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './Oauth2')),
  },
  {
    path: '*',
    exact: false,
    lazy: false,
    component: lazy(() => import(/* webpackChunkName: "SceneOther" */ './PageNotFound')),
  },
]

const App = () => (
  <HelmetProvider>
    <MainLayout>
      <MainHeader />
      <ErrorBoundary
        FallbackComponent={NotFound}
        onError={ErrorBoundaryHandler}
      >
        <Suspense fallback={<AnimatedLoader />}>
          <Switch>
            <RoutesFromConfig config={config} />
            <Redirect to={ROUTE_ROOT_PATH} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </MainLayout>

    <HashRouter hashType="noslash">
      <Route path={`/${ROUTE_LOGIN_HASH}`}>
        <LoginModal />
      </Route>
      <Route
        exact
        path={`/${ROUTE_RESET_NEW_PASSWORD_HASH}`}
      >
        <SetPasswordForm />
      </Route>
      <Route
        exact
        path={`/${ROUTE_RESET_PASSWORD_HASH}`}
      >
        <LostPasswordForm />
      </Route>
      <Route
        exact
        path={`/${ROUTE_SET_NEW_EMAIL}`}
      >
        <SetNewEmail />
      </Route>
    </HashRouter>
  </HelmetProvider>
)

export { App }
