import { useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { clsx } from 'clsx'
import ConditionalWrap from 'conditional-wrap'
import PropTypes from 'prop-types'
import React from 'react'
import { Else, If, Then } from 'react-if'
import { Link } from 'react-router-dom'

import Card from '../../../Theme/Card/Card'
import { BREAKPOINT_MIN_MD } from '../../../Theme/stylesUtil'
import { EventCardDesktop } from './EventCardDesktop'
import { EventCardMobile } from './EventCardMobile'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[1],
  },
  active: {
    cursor: 'pointer',
    transition: theme.transitions.create(['transform', 'box-shadow']),
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[6],
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    height: '100%',
  },
}))

const EventCard = (props) => {
  const {
    eventId,
    eventType,
    backgroundSrc,
    title,
    categoryName,
    playersCountName,
    prize,
    link,
    ownerName,
    ownerAvatarSrc,
    stepper,
    mobileStepper,
    renderRestoreEvent,
  } = props
  const classes = useStyles()
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)
  const sharedProps = { eventType, title, backgroundSrc, prize, playersCountName, categoryName, ownerName }

  return (
    <Card className={clsx(classes.root, eventId && classes.active)}>
      <ConditionalWrap
        condition={!!link}
        wrap={(children) => (
          <Link
            draggable={false}
            className={classes.link}
            to={link}
          >
            {children}
          </Link>
        )}
      >
        <If condition={isBiggerThanMDScreen}>
          <Then>
            <EventCardDesktop
              eventId={eventId}
              stepper={stepper}
              renderRestoreEvent={renderRestoreEvent}
              {...sharedProps}
            />
          </Then>
          <Else>
            <EventCardMobile
              ownerAvatarSrc={ownerAvatarSrc}
              mobileStepper={mobileStepper}
              {...sharedProps}
            />
          </Else>
        </If>
      </ConditionalWrap>
    </Card>
  )
}

EventCard.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  backgroundSrc: PropTypes.string,
  prize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playersCountName: PropTypes.string,
  categoryName: PropTypes.string,
  ownerName: PropTypes.string,
  ownerAvatarSrc: PropTypes.string,
  stepper: PropTypes.node,
  mobileStepper: PropTypes.node,
  isSoftDeleted: PropTypes.bool,
}

export { EventCard }
