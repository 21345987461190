import {
  // navigateToChatPage,
  navigateToEventsListPage,
  navigateToGuidesPage,
  navigateToMapsListPage,
  navigateToPrefabsListPage,
  navigateToRootPage,
} from '../../../Scenes/navigators'
import { FA_ICONS, FA_KIT_ICONS } from '../../../Theme/font-awesome-icon-constants'

export const sidebarListConfig = [
  {
    title: 'Home',
    link: navigateToRootPage(true),
    icon: FA_ICONS.HOUSE,
  },
  {
    title: 'Events',
    link: navigateToEventsListPage(true),
    icon: FA_ICONS.TROPHY,
  },
  {
    title: 'Maps',
    link: navigateToMapsListPage(true),
    icon: FA_ICONS.MAP,
  },
  {
    title: 'Prefabs',
    link: navigateToPrefabsListPage(true),
    icon: FA_KIT_ICONS.PREFAB,
  },
  // temporary remove this btn from menu
  // {
  //   title: 'Messages',
  //   link: navigateToChatPage(true),
  //   icon: FA_ICONS.MESSAGE_DOTS,
  // },
  {
    title: 'Guides',
    link: navigateToGuidesPage(true),
    icon: FA_ICONS.GRADUATION_CAP,
  },
]
